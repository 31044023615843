// Constants
import { PREFIX_COLLECTIONS } from '@/constants'
import firebase from 'firebase/app'
import GenericDB from '../generic-db'
import firestore from '../async-firestore'

export default class Counters extends GenericDB {
  // Each "defaultModel" must be defined in each Class
  defaultModel = {}

  constructor() {
    super(`${PREFIX_COLLECTIONS.horeca}counters`)
  }

  /**
   * Devuelve el numero del pedido actual
   */
  async getCount(counterType) {
    // Capturamos la Referencia del modelo
    const ref = (await firestore()).collection(this.collectionPath)

    // Buscamos los fragmentos del tipo de contador
    const result = await ref
      .doc(counterType)
      .collection('shards')
      .get()

    // Sumamos todos los numeros para obtener el indice total
    let totalCount = 0
    result.forEach(doc => (totalCount += doc.data().count))

    return totalCount
  }

  /**
   * Incrementa el numero del pedido actual
   */
  async incrementCounter(counterType) {
    // Capturamos la Referencia del modelo
    const ref = (await firestore()).collection(this.collectionPath)

    // Obtenemos el numero de fragmentos que existen
    const { numShards } = (await ref.doc(counterType).get()).data()

    // Elegimos un fragmento aleatoríamente
    const shardId = Math.floor(Math.random() * numShards).toString()

    // Obtenemos la Referencia del fragmento
    const shardRef = ref
      .doc(counterType)
      .collection('shards')
      .doc(shardId)

    // Sumamos 1 en el contador del fragmento aleatorio
    shardRef.update('count', firebase.firestore.FieldValue.increment(1))

    return true
  }

  // Esta función sirve para crear otros contadores de forma automatica
  async createCounter(counterType) {
    const ref = (await firestore())
      .collection(this.collectionPath)
      .doc(counterType)
    ref.set({ numShards: 5 })

    for (let i = 0; i < 5; i += 1) {
      const shardRef = ref.collection('shards').doc(i.toString())
      shardRef.set({ count: 0 })
    }
  }
}
