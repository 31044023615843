<template>
  <PanelSection :title="formLabels.labelSectionTitle" :hide-back-button="false">
    <div class="company-keys-form">
      <v-container>
        <CustomCard :card-v-options="{ width: '30%' }">
          <v-form novalidate @submit.prevent="onSubmit">
            <!-- code -->
            <v-text-field
              v-model="$v.formFields.companyKeysId.$model"
              label="Código cliente"
              :error-messages="checkFieldErrors('companyKeysId')"
              @input="touchField('companyKeysId')"
              @blur="touchField('companyKeysId')"
            />
            <!-- company Name -->
            <v-text-field
              v-model="$v.formFields.sticker12.$model"
              label="Nº Etiquetas pequeñas"
              type="number"
              :error-messages="checkFieldErrors('sticker12')"
              @input="touchField('sticker12')"
              @blur="touchField('sticker12')"
            />
            <!-- promoter -->
            <v-text-field
              v-model="$v.formFields.sticker30.$model"
              label="Nº Etiquetas grandes"
              type="number"
              :error-messages="checkFieldErrors('sticker30')"
              @input="touchField('sticker30')"
              @blur="touchField('sticker30')"
            />
            <!-- action buttons -->
            <v-card-actions>
              <FormButtons
                :accept-button-text="formLabels.labelAcceptButton"
                :accept-button-loading="formProcessing"
                :cancel-button-text="formLabels.labelCancelButton"
                @onClickCancelButton="handleClickCancelButton"
              />
            </v-card-actions>
          </v-form>
        </CustomCard>
      </v-container>
    </div>
  </PanelSection>
</template>

<script>
// Components
import CustomCard from '@/components/ui/CustomCard'
import PanelSection from '@/components/ui/PanelSection'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required, integer } from 'vuelidate/lib/validators'
// Services
import {
  getStickersOrdersById,
  createStickerOrder
} from '@/services/stickersOrders'
import { getCompanyKeysById } from '@/services/companyKeys'
import { getCompanyById } from '@/services/company'
import { getPlaceByCompanyKeysId } from '@/services/place'
import { incrementCounter, getCount } from '@/services/counters'

export default {
  name: 'CompanyKeysForm',
  components: {
    CustomCard,
    PanelSection,
    FormButtons
  },
  mixins: [formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Data item
      id: this.$route.params.id || null,
      // Form
      formFields: {
        companyKeysId: null,
        sticker12: 0,
        sticker30: 0
      },
      formFieldsValidations: {
        companyKeysId: {
          required: 'No puede quedar vacío'
        },
        sticker12: {
          required: 'No puede quedar vacío',
          integer: 'Debe ser un número sin decimales'
        },
        sticker30: {
          required: 'No puede quedar vacío',
          integer: 'Debe ser un número sin decimales'
        }
      }
    }
  },
  computed: {
    /**
     * Get the labels (texts) to show in the formulary
     */
    formLabels() {
      return {
        labelAcceptButton: this.id ? 'Guardar' : 'Crear',
        labelCancelButton: 'Cancelar',
        labelSectionTitle: this.id
          ? 'Editar pedido de etiquetas QR'
          : 'Crear pedido de etiquetas QR'
      }
    }
  },
  async mounted() {
    await this.setItemData()
  },
  methods: {
    /**
     * When click on "cancel" button
     */
    handleClickCancelButton() {
      this.redirectToList()
    },
    /**
     * Redirect to list
     */
    redirectToList() {
      this.routerPushTo({
        path: '/stickers-orders'
      })
    },
    /**
     * Set the item data
     */
    async setItemData(id) {
      if (id) {
        const itemData = await getStickersOrdersById(id)
        if (!itemData) {
          this.redirectToList()
        } else {
          this.formFields = {
            companyKeysId: itemData.companyKeysId || null,
            sticker12: itemData.stickers.sticker12 || 0,
            sticker30: itemData.stickers.sticker30 || 0
          }
        }
      }
    },
    /**
     * Is triggered after the form is correctly
     * validated by "Vue-validate"
     */
    async afterSubmit() {
      try {
        const companyKey = await getCompanyKeysById(
          this.formFields.companyKeysId
        )
        const place = await getPlaceByCompanyKeysId(
          this.formFields.companyKeysId
        )

        if (!place) {
          throw new Error(
            'No existe establecimiento relacionado al código indicado'
          )
        }

        const company = await getCompanyById(Object.keys(place.companies)[0])

        if (!company) {
          throw new Error('No existe compañía con dicho identificador')
        }

        await incrementCounter('stickersOrders')
        const countOrder = await getCount('stickersOrders')
        const companiesField = {}
        companiesField[`${company.id}`] = true
        const data = {
          area: companyKey.area,
          order: countOrder,
          companyKeysId: this.formFields.companyKeysId,
          companies: companiesField,
          places: company.places,
          companyName: company.name,
          deliveryType: 'send',
          status: 'accepted',
          stickers: {
            sticker12: this.formFields.sticker12,
            sticker30: this.formFields.sticker30
          }
        }
        createStickerOrder(data)
        // show info
        this.modifyAppAlert({
          text: 'Los cambios se guardaron correctamente',
          visible: true
        })
        // Redirect to list
        this.redirectToList()
      } catch (error) {
        // show error
        this.modifyAppAlert({
          dismissibleTimeOut: -1,
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.formProcessing = false
      }
    }
  },
  // Validations with Vue-validate
  validations: {
    formFields: {
      companyKeysId: {
        required
      },
      sticker12: {
        required,
        integer
      },
      sticker30: {
        required,
        integer
      }
    }
  }
}
</script>
