import Counters from '@/firebase/models/counters'

const counters = new Counters()

// Devuelte el indice actual
export async function getCount(counterType) {
  const count = await counters.getCount(counterType)
  return count
}

// Aumenta el indice actual
export async function incrementCounter(counterType) {
  await counters.incrementCounter(counterType)
  return true
}

// Esta función sirve para crear otros contadores de forma automatica
export async function createCounter(counterType) {
  await counters.createCounter(counterType)
  return true
}
